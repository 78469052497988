import PageStyles from "./PageStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const ListPageStyles = {
  ...PageStyles,
  contentOuter: {
    minHeight: "400px", // to match wrapper__content
    background: "#fff",
  },
  mainColumnInner: {
    display: "flex",
    flexDirection: "column",
  },
  mainHeader: {
    height: 85,
  },
  contentContainer: {
    ...gStyles.contentContainer,
  },
  listContentContainer: {
    padding: 0,
    [ScreenSizes.xsOnly]: {
      padding: 0,
    },
    [ScreenSizes.mdOnly]: {
      padding: 0,
    },
    [ScreenSizes.lgOnly]: {
      paddingLeft: gStyles.contentContainer?.paddingLeft,
      paddingRight: gStyles.contentContainer?.paddingRight,
      paddingTop: 0,
    },
    [ScreenSizes.xlAndAbove]: {
      paddingLeft: gStyles.contentContainer?.paddingLeft,
      paddingRight: gStyles.contentContainer?.paddingRight,
      paddingTop: 0,
    },
  },
  above: {
    marginBottom: gStyles.mainSectionTitle.marginBottom,

    [ScreenSizes.mdAndBelow]: {
      marginBottom:
        gStyles.mainSectionTitle[ScreenSizes.mdAndBelow] &&
        gStyles.mainSectionTitle[ScreenSizes.mdAndBelow].marginBottom,
      display: "block",
    },
    [ScreenSizes.lgOnly]: {
      marginBottom:
        gStyles.mainSectionTitle[ScreenSizes.lgOnly] &&
        gStyles.mainSectionTitle[ScreenSizes.lgOnly].marginBottom,
    },
  },
  contentRow: {
    [ScreenSizes.mdAndBelow]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  contentCol: {
    [ScreenSizes.mdAndBelow]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: "2rem",
    },
  },
  content: {
    [ScreenSizes.mdAndBelow]: {
      marginBottom: "2rem",
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    gap: "1rem",
    marginBottom: "1rem",
    [ScreenSizes.lgAndAbove]: {
      gap: "1.5rem",
    },
  },
  overrideGap: {
    gap: "1rem",
    [ScreenSizes.lgAndAbove]: {
      gap: "1rem",
    },
  },
  sectionTitle: {
    ...gStyles.mainSectionTitle,
  },
  titleContainer: {
    display: "flex",
    flex: "1 0 100%",
    marginBottom: "1rem",
    flexDirection: "row",
    width: "100%",
    [ScreenSizes.lgAndAbove]: {
      marginBottom: 20,
      flex: "1 1 auto",
    },
  },
  titleContainerMargin: {
    [ScreenSizes.lgAndAbove]: {
      marginBottom: 0,
    },
  },
  tabTitle: {
    // TODO: create a EntityStyles.js and move this to there.
    fontSize: "1em",
    color: colours.oldSecondary,
    margin: "0 0 1em",
    padding: 0,
  },
  title: {
    ...gStyles.archivePageTitle,
    display: "flex",
    alignItems: "center",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
    flexWrap: "wrap",
    width: "100%",
    height: "max-content",
    gap: ".5rem",

    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
  },
  actionButtonsWithTabButtons: {
    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  actionButton: {
    width: "max-content",
    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1rem",
      marginRight: "1rem",
      flex: "0 1 auto",

      ":last-child": {
        marginRight: 0,
      },
    },
  },
  hasGuestButton: {
    [ScreenSizes.mdAndBelow]: {
      width: "max-content",
      marginLeft: "auto",
      marginRight: 0,
    },
    [ScreenSizes.lgAndAbove]: {
      marginRight: 0,
    },
    [createBreakpoint({ min: 835 })]: {
      marginLeft: "auto",
    },
    [ScreenSizes.mdAndBelow]: {
      marginLeft: 0,
    },
  },
  listSort: {
    [ScreenSizes.lgAndAbove]: {
      marginRight: 0,
    },
  },
  listCount: {
    width: "100%",
    marginBottom: 10,
    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      display: "block",
      alignItems: "center",
    },
    [ScreenSizes.xlAndAbove]: {
      width: "max-content",
      marginBottom: 5,
    },
  },
  fullWidth: {
    [ScreenSizes.lgAndAbove]: {
      width: "100%",
    },
  },
  tabButtons: {
    marginRight: "auto",
    marginBottom: "0.5rem",
    width: "100%",
    fontSize: "0.7rem",

    [ScreenSizes.mdAndAbove]: {
      width: "auto",
      marginBottom: 0,
    },
  },
  filterButton: {
    [ScreenSizes.mdAndBelow]: {
      width: "max-content",
    },
    [ScreenSizes.lgAndAbove]: {
      marginLeft: "auto",
    },
    [createBreakpoint({ max: 873 })]: {
      marginLeft: 0,
    },
  },
  filters: {
    fontSize: "0.9em",
    display: "none",
    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
  noMarginLeft: {
    [ScreenSizes.lgAndAbove]: {
      marginLeft: 0,
    },
  },
  filterSearch: {
    [ScreenSizes.mdAndBelow]: {
      width: "100%",
      height: "100%",
      padding: "0.7rem 0",
    },
    [ScreenSizes.lgAndAbove]: {
      marginLeft: "auto",
    },
  },
  filterDateRange: {},
  sidebarBtns: {
    height: 48,
    display: "flex",
    justifyContent: "space-between",
  },
  subscribeButton: {
    flexBasis: "48%",
  },
  shareButton: {
    flexBasis: "48%",
  },
  panel: {
    marginTop: 50,
  },
  panelHeader: {
    paddingBottom: 10,
  },
  panelH2: {
    textTransform: "uppercase",
    color: "var(--color-neutral-d4)",
    fontSize: "0.8125rem",
    ...gStyles.fontBold,
  },
  sidebarTitleSpacer: {
    marginTop: "6.8em",
  },
  sidebarSection: {
    ...gStyles.sidebarSection,
  },
};

export default ListPageStyles;
